<template>
  <div class="bg-img">
    <the-modal class="col-lg-8 offset-lg-2" v-show="videoActive">
      <b-icon-x
        id="close-x"
        @click="closeEverything"
        style="
          position: absolute;
          color: white;
          z-index: 150;
          right: -50px;
          height: 35px;
          width: 35px;
          opacity: 0.5;
        "
        class="d-none d-lg-inline"
      ></b-icon-x>
      <video
        class="embed-responsive embed-responsive-16by9 video"
        controls
        ref="indexVideo"
        :src="videoMp4Url"
        type="video/mp4"
      ></video>
    </the-modal>

    <b-row class="w-100 ml-auto mr-auto" style="max-width: 1200px">
      <b-col>
        <b-row
          class="d-none d-md-block row"
          style="height: 120px; width: 100%; z-index: 100"
        >
          <b-col>
            <transition name="fadeFirst">
              <h1 v-show="showFirstMessage" class="hero-message-title">
                {{ activeHeroTitle | capitalizeAll }}
              </h1>
            </transition>
            <transition name="fadeSecond">
              <h1 v-show="showFirstMessage" class="hero-message-description">
                {{ activeHeroDescription | capitalizeAll }}
              </h1>
            </transition>

            <transition name="fadeFirst">
              <h1 v-show="showSecondMessage" class="hero-message-title">
                {{ activeHeroTitle | capitalizeAll }}
              </h1>
            </transition>
            <transition name="fadeSecond">
              <h1 v-show="showSecondMessage" class="hero-message-description">
                {{ activeHeroDescription | capitalizeAll }}
              </h1>
            </transition>

            <transition name="fadeFirst">
              <h1 v-show="showThirdMessage" class="hero-message-title">
                {{ activeHeroTitle | capitalizeAll }}
              </h1>
            </transition>
            <transition name="fadeSecond">
              <h1 v-show="showThirdMessage" class="hero-message-description">
                {{ activeHeroDescription | capitalizeAll }}
              </h1>
            </transition>
          </b-col>
        </b-row>

        <b-row class="d-md-none">
          <b-col style="margin-top: 70px">
            <h1 class="w-100 hero-message-title">
              {{
                this.$store.state.data.heroMessagesVendorLaunch[0].title
                  | capitalizeAll
              }}
            </h1>
            <h1 class="w-100 hero-message-description">
              {{
                this.$store.state.data.heroMessagesVendorLaunch[0].description
                  | capitalizeAll
              }}
            </h1>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex" style="gap: 2rem">
            <button class="video-thumb-1 text-center text-white">
              <img
                @click="
                  playVideo(
                    'https://unityworks-a.akamaihd.net/anon.unityworks/M/F/J/56972529_DA2025ProductHighlightsFINAL_250211083253_4.mp4'
                  )
                "
                src="@/assets/video_thumb_da.jpg"
              />
              <p>Dealer Alchemist Intro</p>
            </button>

            <button class="video-thumb-2 text-center text-white">
              <img
                @click="
                  playVideo(
                    'https://unityworks-a.akamaihd.net/anon.unityworks/4/3/B/56154165_Digital_Dealer_Program_241218033212_4.mp4'
                  )
                "
                src="@/assets/video_thumb_intice.jpg"
              />
              <p>Intice Intro</p>
            </button>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="8" lg="10">
            <p class="index-copy">
              Dealer Alchemist and Intice are trailblazers in the automotive
              digital space. And now, for the first time, they are both
              certified website providers with ADDP!
            </p>
          </b-col>
        </b-row>

        <router-link to="/category/dealer-websites">
          <button type="button" class="btn btn-outline-light mt-4 pr-3 pl-3">
            <img
              style="
                width: 20px;
                height: 20px;
                margin-right: 10px;
                padding-bottom: 1px;
              "
              src="@/assets/rocket.svg"
            />Learn More
          </button>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TheModal from "@/components/TheModal.vue";
import { BIconX } from "bootstrap-vue";

export default {
  name: "home",
  components: {
    TheModal,
    BIconX,
  },

  metaInfo: {
    title: "Home",
  },

  data() {
    return {
      formErrors: {},
      currentTime: 0,
      heroMessages: this.$store.state.data.heroMessagesVendorLaunch,
      activeHeroTitle: this.$store.state.data.heroMessagesVendorLaunch[0].title,
      activeHeroDescription: this.$store.state.data.heroMessagesVendorLaunch[0]
        .description,
      showFirstMessage: true,
      showSecondMessage: false,
      showThirdMessage: false,
      videoMp4Url: "",
    };
  },

  watch: {
    $route: "fetchData",
    videoActive: function() {
      if (!this.videoActive) {
        // this.resetSupportForm();
        this.stopVideo();
      }
    },
  },

  created() {
    this.startTimer();
  },

  methods: {
    playVideo(videoMp4Url) {
      this.videoMp4Url = videoMp4Url;
      window.scrollTo(0, 0);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleVideo");
      setTimeout(() => {
        this.indexVideo.play();
      }, 250);
    },

    stopVideo() {
      this.indexVideo.pause();
      this.indexVideo.currentTime = 0;
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    startTimer() {
      setInterval(this.setHeroStuff, 1000);
    },

    setHeroStuff() {
      // console.log(this.currentTime);

      if (this.currentTime == 1) {
        this.activeHeroTitle = this.heroMessagesVendorLaunch[0].title;
        this.activeHeroDescription = this.heroMessagesVendorLaunch[0].description;
        this.showFirstMessage = true;
      }

      if (this.currentTime == 6) {
        this.showFirstMessage = false;
      }

      if (this.currentTime == 7) {
        this.showSecondMessage = true;
        this.activeHeroTitle = this.heroMessages[1].title;
        this.activeHeroDescription = this.heroMessages[1].description;
      }

      if (this.currentTime == 10) {
        this.showSecondMessage = false;
      }

      if (this.currentTime == 11) {
        this.showThirdMessage = true;
        this.activeHeroTitle = this.heroMessages[2].title;
        this.activeHeroDescription = this.heroMessages[2].description;
      }

      if (this.currentTime == 15) {
        this.showThirdMessage = false;
        this.currentTime = 0;
      }

      this.currentTime = this.currentTime + 1;
    },
  },

  computed: {
    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },

    indexVideo() {
      return this.$refs.indexVideo;
    },
  },
};
</script>

<style scoped>
.fadeFirst-enter {
  opacity: 0;
  margin-left: 50px;
}

.fadeFirst-enter-active {
  transition: all 0.3s ease-in-out;
}

.fadeFirst-leave-active {
  transition: all 0.3s ease-in-out;
}

.fadeFirst-leave-to {
  opacity: 0;
  margin-left: 80px;
}

.fadeSecond-enter {
  opacity: 0;
  margin-left: -50px;
}

.fadeSecond-enter-active {
  transition: all 0.3s ease-in-out;
}

.fadeSecond-leave-active {
  transition: all 0.3s ease-in-out;
}

.fadeSecond-leave-to {
  opacity: 0;
  margin-left: 80px;
  margin-top: 10px;
}

#close-x:hover {
  cursor: pointer;
  opacity: 1;
}

.bg-img {
  background-image: url("../assets/da_bg.jpg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  margin: 0px -15px 0px -15px;
  overflow: hidden;
  padding: 30px 25px 150px 25px;
}

.video-thumb-1,
.video-thumb-2 {
  background: none;
  border: none;
}

.video-thumb-1:hover,
.video-thumb-2:hover {
  filter: brightness(1.15);
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.index-copy {
  color: white;
}

@media screen and (min-width: 1400px) {
  .hero-message-title {
    color: white;
    font-weight: 300;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
    margin-top: 50px;
  }

  .hero-message-description {
    color: white;
    font-weight: 700;
    font-size: 35px;
    margin-top: 5px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  }

  .video-thumb-1 {
    margin-top: 75px;
    width: auto;
    -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  }

  .video-thumb-2 {
    margin-top: 75px;
    width: auto;
    -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  }

  .video {
    position: absolute;
    z-index: 250;
    background-color: black;
    border-radius: 5px;
  }

  .video:focus {
    outline: none;
  }

  .index-copy {
    margin-top: 50px;
  }

  .btn-outline-light {
    background-color: rgba(0, 0, 0, 0.6);
    border: 1.4px solid white;
    font-weight: 400;
    border-radius: 30px;
    transition: none;
  }

  .btn-outline-light:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    transition: none;
  }

  .btn-outline-light:active {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
    transition: none;
  }

  #index-text-box {
    width: 650px;
    margin-top: 50px;
    line-height: 1.5em;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  }
}

@media screen and (max-width: 1399px) {
  .hero-message-title {
    width: 100%;
    color: white;
    font-weight: 300;
    font-size: 40px;
    line-height: 1;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
    margin-top: 50px;
  }

  .hero-message-description {
    color: white;
    font-weight: 700;
    font-size: 35px;
    margin-top: 5px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  }

  .index-copy {
    margin-top: 50px;
  }

  .video {
    position: absolute;
    z-index: 250;
    background-color: black;
    border-radius: 5px;
  }

  .video:focus {
    outline: none;
  }

  .btn-outline-light {
    background-color: rgba(0, 0, 0, 0.6);
    border: 1.4px solid white;
    font-weight: 400;
    border-radius: 30px;
    transition: none;
  }

  .btn-outline-light:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    transition: none;
  }

  .btn-outline-light:active {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: white !important;
    transition: none;
  }

  #cover-img {
    z-index: 5;
    background-image: url("../assets/da_bg.jpg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    border-bottom: 3px solid #69a1cf;
    height: 800px;
  }

  .video-thumb-1 {
    margin-top: 60px;
    width: 250px;
    -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  }

  .video-thumb-2 {
    margin-top: 60px;
    width: 250px;
    -webkit-box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.2);
  }

  .video-thumb-1:hover .video-thumb-2:hover {
    filter: brightness(1.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
  }

  .video-thumb-1 img,
  .video-thumb-2 img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .hero-message-title {
    color: white;
    font-weight: 300;
    font-size: 35px;
    line-height: 1;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  }

  .hero-message-description {
    color: white;
    font-weight: 700;
    font-size: 30px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  }

  #index-text-box {
    line-height: 1.5em;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  }

  .video {
    position: fixed;
    width: 100%;
    right: 0px;
    border-radius: 0px;
    margin-top: 25%;
    z-index: 400;
  }

  .video-thumb-1 {
    /* display: none; */
    width: 40%;
  }

  .video-thumb-1 .video-thumb-2 img {
    width: 100%;
  }

  .video-thumb-2 {
    /* display: none; */
    width: 40%;
  }

  .video-thumb-2 img {
    width: 100%;
  }

  #cover-img {
    position: abolute;
    z-index: 5;
    background-image: url("../assets/da_bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 3px solid #69a1cf;
    height: 700px;
  }

  .btn {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .hero-message-title {
      margin-top: 0px;
      font-size: 30px;
    }

    .hero-message-description {
      font-size: 25px;
    }

    .video-thumb-1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      align-items: center;
      margin-top: 25px;
    }

    .video-thumb-2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      align-items: center;
      margin-top: 25px;
    }

    .index-copy {
      margin-top: 10px;
    }

    .bg-img {
      background-image: url("../assets/da_bg.jpg");
      background-repeat: no-repeat;
      background-position: right;
      background-size: cover;
      overflow: hidden;
    }
  }
}
</style>
